// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Logo from "./Logo.bs.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ExternalLink from "./ExternalLink.bs.js";

var Raw = {};

var query = (graphql`
  query SiteMetadata  {
    site  {
      siteMetadata  {
        title
        description
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.site;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.siteMetadata;
    var tmp$1;
    if (value$2 == null) {
      tmp$1 = undefined;
    } else {
      var value$3 = value$2.title;
      var value$4 = value$2.description;
      tmp$1 = {
        title: !(value$3 == null) ? value$3 : undefined,
        description: !(value$4 == null) ? value$4 : undefined
      };
    }
    tmp = {
      siteMetadata: tmp$1
    };
  }
  return {
          site: tmp
        };
}

function serialize(value) {
  var value$1 = value.site;
  var site;
  if (value$1 !== undefined) {
    var value$2 = value$1.siteMetadata;
    var siteMetadata;
    if (value$2 !== undefined) {
      var value$3 = value$2.description;
      var description = value$3 !== undefined ? value$3 : null;
      var value$4 = value$2.title;
      var title = value$4 !== undefined ? value$4 : null;
      siteMetadata = {
        title: title,
        description: description
      };
    } else {
      siteMetadata = null;
    }
    site = {
      siteMetadata: siteMetadata
    };
  } else {
    site = null;
  }
  return {
          site: site
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var SiteMetadata = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

function Footer(Props) {
  var data = Gatsby.useStaticQuery(query);
  var meta = Belt_Option.getExn(Belt_Option.flatMap(Caml_option.nullable_to_opt(data.site), (function (site) {
              return Caml_option.nullable_to_opt(site.siteMetadata);
            })));
  return React.createElement("footer", {
              className: "bg-gray-100"
            }, React.createElement("div", {
                  className: "max-w-2xl mx-auto py-12 px-4 text-center"
                }, React.createElement(Logo.make, {
                      className: "w-48 mb-6 m-auto"
                    }), React.createElement("p", {
                      className: "text-md mb-6"
                    }, Belt_Option.getExn(Caml_option.nullable_to_opt(meta.description))), React.createElement("small", {
                      className: "block mb-2"
                    }, "Powered by ", React.createElement(ExternalLink.make, {
                          children: "Gatsby",
                          href: "https://www.gatsbyjs.com/"
                        }), ",  ", "Hosted by ", React.createElement(ExternalLink.make, {
                          children: "Vercel",
                          href: "https://vercel.com/"
                        }), "."), React.createElement("small", {
                      className: "block"
                    }, React.createElement(ExternalLink.make, {
                          children: "© 이현섭",
                          href: "https://github.com/HyunSeob"
                        }), ", ", React.createElement(ExternalLink.make, {
                          children: "All rights reserved.",
                          href: "https://creativecommons.org/licenses/by-sa/4.0/deed.ko"
                        }))));
}

var make = Footer;

var $$default = Footer;

export {
  SiteMetadata ,
  make ,
  $$default ,
  $$default as default,
  
}
/* query Not a pure module */
