// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";

var curatedGradient = {
  hd: {
    hd: "red",
    tl: {
      hd: "yellow",
      tl: /* [] */0
    }
  },
  tl: {
    hd: {
      hd: "red",
      tl: {
        hd: "blue",
        tl: /* [] */0
      }
    },
    tl: {
      hd: {
        hd: "red",
        tl: {
          hd: "indigo",
          tl: /* [] */0
        }
      },
      tl: {
        hd: {
          hd: "red",
          tl: {
            hd: "purple",
            tl: /* [] */0
          }
        },
        tl: {
          hd: {
            hd: "yellow",
            tl: {
              hd: "pink",
              tl: /* [] */0
            }
          },
          tl: {
            hd: {
              hd: "green",
              tl: {
                hd: "blue",
                tl: /* [] */0
              }
            },
            tl: {
              hd: {
                hd: "green",
                tl: {
                  hd: "purple",
                  tl: /* [] */0
                }
              },
              tl: {
                hd: {
                  hd: "blue",
                  tl: {
                    hd: "purple",
                    tl: /* [] */0
                  }
                },
                tl: {
                  hd: {
                    hd: "blue",
                    tl: {
                      hd: "pink",
                      tl: /* [] */0
                    }
                  },
                  tl: {
                    hd: {
                      hd: "indigo",
                      tl: {
                        hd: "pink",
                        tl: /* [] */0
                      }
                    },
                    tl: {
                      hd: {
                        hd: "purple",
                        tl: {
                          hd: "pink",
                          tl: /* [] */0
                        }
                      },
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function getColors(text) {
  var charCodeSum = Belt_Array.reduce(Belt_Array.map(Belt_Array.map(text.split(""), (function (param) {
                  return param.charCodeAt(0);
                })), (function (prim) {
              return prim | 0;
            })), 0, (function (a, b) {
          return a + b | 0;
        }));
  var gradient = Belt_List.getExn(curatedGradient, Caml_int32.mod_(charCodeSum, Belt_List.length(curatedGradient)));
  var fromLeftToRight = text.length % 1 === 0;
  var fromColor = Belt_List.getExn(gradient, fromLeftToRight ? 0 : 1);
  var toColor = Belt_List.getExn(gradient, fromLeftToRight ? 1 : 0);
  return [
          fromColor,
          toColor
        ];
}

export {
  curatedGradient ,
  getColors ,
  
}
/* No side effect */
