// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function getExn(arg) {
  return Belt_Option.getExn((arg == null) ? undefined : Caml_option.some(arg));
}

function flatMap(arg, mapper) {
  return Js_null_undefined.fromOption(Belt_Option.flatMap((arg == null) ? undefined : Caml_option.some(arg), (function (opt) {
                    return Caml_option.nullable_to_opt(Curry._1(mapper, opt));
                  })));
}

export {
  getExn ,
  flatMap ,
  
}
/* No side effect */
