// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Logo from "./Logo.bs.js";
import * as React from "react";
import * as Gatsby from "gatsby";

function NavigationBar$NavItem(Props) {
  var children = Props.children;
  var _to = Props.to;
  return React.createElement("li", {
              className: "text-lg font-semibold pl-4"
            }, React.createElement(Gatsby.Link, {
                  to: _to,
                  className: "text-gray-700 hover:text-blue-600 transition-colors",
                  activeClassName: "text-blue-600",
                  children: children
                }));
}

var NavItem = {
  make: NavigationBar$NavItem
};

function NavigationBar$ExternalItem(Props) {
  var children = Props.children;
  var _to = Props.to;
  return React.createElement("li", {
              className: "text-lg font-semibold pl-4"
            }, React.createElement("a", {
                  className: "text-gray-700 hover:text-blue-600 transition-colors",
                  href: _to,
                  rel: "noopener",
                  target: "_blank"
                }, children));
}

var ExternalItem = {
  make: NavigationBar$ExternalItem
};

function NavigationBar(Props) {
  return React.createElement("header", undefined, React.createElement("div", {
                  className: "max-w-2xl mx-auto px-4 py-8 flex justify-between items-center"
                }, React.createElement(Gatsby.Link, {
                      to: "/",
                      className: "block w-48 mr-12 focus:ring-2",
                      children: React.createElement(Logo.make, {})
                    }), React.createElement("nav", undefined, React.createElement("ul", {
                          className: "flex"
                        }, React.createElement(NavigationBar$NavItem, {
                              children: "Posts",
                              to: "/"
                            }), React.createElement(NavigationBar$ExternalItem, {
                              children: "GitHub",
                              to: "https://github.com/HyunSeob/seob.dev"
                            })))));
}

var make = NavigationBar;

var $$default = NavigationBar;

export {
  NavItem ,
  ExternalItem ,
  make ,
  $$default ,
  $$default as default,
  
}
/* Logo Not a pure module */
