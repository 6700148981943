// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function ExternalLink(Props) {
  var className = Props.className;
  var children = Props.children;
  var href = Props.href;
  return React.createElement("a", {
              className: [
                  "text-blue-600",
                  "hover:shadow-link",
                  "transition-shadow",
                  "font-bold",
                  Belt_Option.getWithDefault(className, "")
                ].join(" "),
              href: href,
              rel: "noopener noreferrer",
              target: "_blank"
            }, children);
}

var make = ExternalLink;

export {
  make ,
  
}
/* react Not a pure module */
