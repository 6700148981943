// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactHelmet from "react-helmet";

function SEO(Props) {
  var title = Props.title;
  var description = Props.description;
  var url = Props.url;
  var children = Props.children;
  return React.createElement(ReactHelmet.Helmet, {
              defaultTitle: title,
              children: null
            }, React.createElement("meta", {
                  content: description,
                  name: "description"
                }), React.createElement("meta", {
                  content: "website",
                  property: "og:type"
                }), React.createElement("meta", {
                  content: title,
                  property: "og:title"
                }), React.createElement("meta", {
                  content: url,
                  property: "og:url"
                }), React.createElement("meta", {
                  content: "seob.dev",
                  property: "og:site_name"
                }), React.createElement("meta", {
                  content: description,
                  property: "og:description"
                }), React.createElement("meta", {
                  content: "ko_KR",
                  property: "og:locale"
                }), React.createElement("meta", {
                  content: "https://seob.dev/og-img.png",
                  property: "og:image"
                }), React.createElement("meta", {
                  content: "summary",
                  name: "twitter:card"
                }), React.createElement("meta", {
                  content: title,
                  name: "twitter:title"
                }), React.createElement("meta", {
                  content: description,
                  name: "twitter:description"
                }), React.createElement("meta", {
                  content: "https://seob.dev/og-img.png",
                  name: "twitter:image"
                }), React.createElement("meta", {
                  content: "@HyunSeob_",
                  name: "twitter:creator"
                }), React.createElement("link", {
                  href: url,
                  rel: "canonical"
                }), Belt_Option.getWithDefault(children, null));
}

var make = SEO;

var $$default = SEO;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
